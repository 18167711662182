import axios from "axios";
import { useState, useEffect, useRef } from "react";
import DataTable from "../DataTable";
import "./Admin.css";
import RegconLogo from "../../assets/regcon.png";

const API_URL = process.env?.REACT_APP_API_URL || "";

const AdminPage = () => {
    const [isKeysLoading, setIsKeysLoading] = useState(false);
    const [keys, setKeys] = useState([]);
    const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
    const [feedback, setFeedback] = useState([]);
    const [isRequestsLoading, setIsRequestsLoading] = useState(false);
    const [requests, setRequests] = useState([]);
    const [logins, setLogins] = useState([])
    const [supportTickets, setSupportTickets] = useState([])

    const [displayPage, setDisplayPage] = useState(0)

    const licenseKeyInputRef = useRef();
    const licenseKeyEmailInputRef = useRef();
    const licenseKeyExpireRef = useRef();

    const downloadAllKeys = () => {
        setIsKeysLoading(true);
        axios.get(`${API_URL}/admin/keys`).then((resp) => {
            setKeys(resp.data.licenses);
            setIsKeysLoading(false);
        });
    };

    const downloadAllLogins = () => {
        setIsKeysLoading(true);
        axios.get(`${API_URL}/admin/logins`).then((resp) => {
            setLogins(resp.data.licenses)
        });
    };

    const downloadAllFeedback = () => {
        setIsFeedbackLoading(true);
        axios.get(`${API_URL}/admin/feedback`).then((resp) => {
            console.log('feedback', resp.data)
            setFeedback(resp.data.feedback);
            setIsFeedbackLoading(false);
        });
    };

    const downloadAllSupportTickets = () => {
        //setIsFeedbackLoading(true);
        axios.get(`${API_URL}/admin/tickets`).then((resp) => {
            setSupportTickets(resp.data.tickets);
        });
    };

    const downloadAllRequests = () => {
        setIsRequestsLoading(true);
        axios.get(`${API_URL}/admin/accounts`).then((resp) => {
            setRequests(resp.data.accounts);
            setIsRequestsLoading(false);
        });
    };

    const productMap = {
        ectd_viewer: "eCTD Viewer",
    };

    const generateRandomString = (length) => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    };
    const adminMarkConacted = (userId) => {
        axios
            .post(`${API_URL}/admin/accounts/contact`, {
                userId,
            })
            .then((resp) => {
                downloadAllRequests();
            })
            .catch((resp) => {
                console.log("Cannot Delete key", resp);
            });
    };
    const adminDeleteWaitingRoomUser = (userId) => {
        axios
            .post(`${API_URL}/admin/accounts/delete`, {
                userId,
            })
            .then((resp) => {
                downloadAllRequests();
            })
            .catch((resp) => {
                console.log("Cannot Delete key", resp);
            });
    };
    const adminUpgradeUser = (userId) => {
        axios
            .post(`${API_URL}/admin/accounts/upgrade`, {
                userId,
            })
            .then((resp) => {
                downloadAllRequests();
            })
            .catch((resp) => {
                console.log("Cannot deactivate key", resp);
            });
    };
    const adminDeactivateKey = (licenseKey) => {
        axios
            .post(`${API_URL}/admin/key/reset`, {
                licenseKey,
            })
            .then((resp) => {
                downloadAllKeys();
            })
            .catch((resp) => {
                console.log("Cannot deactivate key", resp);
            });
    };

    const adminAddKey = () => {
        axios
            .post(`${API_URL}/admin/key/create`, {
                licenseKey: licenseKeyInputRef.current.value,
                email: licenseKeyEmailInputRef.current.value,
                daysToExpire: Number(licenseKeyExpireRef.current.value),
            })
            .then((resp) => {
                downloadAllKeys();
            })
            .catch((resp) => {
                console.log("Cannot create key", resp);
            });
    };

    const adminSetExpiration = (licenseKey, newDaysToExpire) => {
        axios
            .post(`${API_URL}/admin/key/setexpiration`, {
                licenseKey,
                daysToExpire: Number(
                    document.getElementById(newDaysToExpire).value
                ),
            })
            .then((resp) => {
                downloadAllKeys();
            })
            .catch((resp) => {
                console.log("Cannot Set expiration", resp);
            });
    };

    const adminDeleteKey = (licenseKey) => {
        axios
            .post(`${API_URL}/admin/key/delete`, {
                licenseKey,
            })
            .then((resp) => {
                downloadAllKeys();
            })
            .catch((resp) => {
                console.log("Cannot Delete key", resp);
            });
    };

    const keyTableColumnMeta = [
        {
            name: "License Key",
            key: "license_key",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Expiration Date",
            key: "expiration_date",
            default: null,
            render: (value) => <p>{new Date(value).toDateString()}</p>,
            filterable: true,
            filterInputType: "date",
        },
        {
            name: "Email",
            key: "email",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Product",
            key: "product",
            default: null,
            render: (value) => <p>{productMap[value]}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Deactivate",
            key: "activated_machine",
            default: null,
            render: (value, row) => (
                <button
                    disabled={!value}
                    onClick={() => adminDeactivateKey(row.license_key)}
                    className='inner-table-button'
                >
                    Deativate
                </button>
            ),
            filterable: false,
        },
        {
            name: "Set Expiration",
            key: "expiration_date",
            default: null,
            render: (value, row, rowIdx) => (
                <div>
                    <label>Days To Expire</label>
                    <br />
                    <div className='expiration-input-box'>
                        <input
                            className='inner-table-input expiration-input'
                            type='number'
                            id={`${rowIdx}-row-expiration`}
                            defaultValue={10}
                        />
                        <button
                            className='inner-table-button'
                            onClick={() =>
                                adminSetExpiration(
                                    row.license_key,
                                    `${rowIdx}-row-expiration`
                                )
                            }
                        >
                            Set
                        </button>
                    </div>
                </div>
            ),
            filterable: false,
        },
        {
            name: "Delete Key",
            key: "license_key",
            default: null,
            render: (value) => (
                <button
                    onClick={() => adminDeleteKey(value)}
                    className='inner-table-button'
                >
                    Delete
                </button>
            ),
            filterable: false,
        },
    ];

    const loginsMeta = [
        {
            name: "Email",
            key: "email",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Name",
            key: "name",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Company",
            key: "company",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Phone",
            key: "phone",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: false,
            filterInputType: "text",
        },
    ]

    const feedbackTableComulmnMeta = [
        {
            name: "Email",
            key: "email",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Type",
            key: "feedback_type",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Message",
            key: "feedback_message",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Timestamp",
            key: "timestamp",
            default: null,
            render: (value) => <p>{value && new Date(value).toLocaleString('us-US')}</p>,
            filterable: true,
            filterInputType: "date",
        },
    ];

    const requestTableComulmnMeta = [
        {
            name: "Email",
            key: "email",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Name",
            key: "name",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Company",
            key: "company",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Phone",
            key: "phone",
            default: null,
            render: (value) => <p>{value}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Timestamp",
            key: "timestamp",
            default: null,
            render: (value) => <p>{value && new Date(value).toLocaleString('us-US')}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Contacted",
            key: "has_been_contacted",
            default: null,
            render: (value) => <p>{value ? "Contacted" : "Not Contacted"}</p>,
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Mark Contacted",
            key: "user_id",
            default: null,
            render: (value, row) => (
                <button
                    className='inner-table-button'
                    disabled={row["has_been_contacted"]}
                    onClick={() => adminMarkConacted(value)}
                >
                    Mark Contacted{" "}
                </button>
            ),
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Delete",
            key: "user_id",
            default: null,
            render: (value, row) => (
                <button
                    className='inner-table-button'
                    onClick={() => adminDeleteWaitingRoomUser(value)}
                >
                    Delete
                </button>
            ),
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Upgrade",
            key: "user_id",
            default: null,
            render: (value) => (
                <button
                    onClick={() => adminUpgradeUser(value)}
                    className='inner-table-button'
                >
                    Upgrade
                </button>
            ),
            filterable: true,
            filterInputType: "text",
        },
        {
            name: "Give Key",
            key: "email",
            default: null,
            render: (value) => (
                <button
                    onClick={() => {
                        licenseKeyEmailInputRef.current.value = value;
                        licenseKeyExpireRef.current.value = 10;
                        licenseKeyInputRef.current.value =
                            generateRandomString(10);
                        document
                            .getElementById("create-license-key-box")
                            .scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                    }}
                    className='inner-table-button'
                >
                    Give
                </button>
            ),
            filterable: true,
            filterInputType: "text",
        },
    ];

    useEffect(() => {
        downloadAllKeys();
        downloadAllFeedback();
        downloadAllRequests();
        downloadAllLogins()
        downloadAllSupportTickets()
    }, []);

    return (
        <div className='main-container'>
            <div className='body-header'>
                <div className='body-header-side'>
                    <a className='header-link main-link' href='/'>
                        <img src={RegconLogo} alt='RegCon' />
                    </a>
                </div>
                <div className='body-header-side stays-on-mobile'>
                    <a className='header-link login-link' href='/home'>
                        Home
                    </a>
                    <a className='header-link login-link' href='/login'>
                        Logout
                    </a>
                </div>
            </div>
            <h1 className='admin-panel-title'>Admin Panel</h1>

            <div style={{
                display: 'flex',
            }}>
                <button onClick={() => setDisplayPage(0)}>Liceneses</button>
                <button onClick={() => setDisplayPage(1)}>Feedback</button>
                <button onClick={() => setDisplayPage(2)}>Accounts</button>
                <button onClick={() => setDisplayPage(3)}>Sign Up Requests</button>
                <button onClick={() => setDisplayPage(4)}>Support Tickets</button>
            </div>

            {displayPage === 0 && (<>  <div className='tool-box'>
                <h2>View License Keys</h2>
                <DataTable
                    isLoading={isKeysLoading}
                    maxPageElements={5}
                    columnMeta={keyTableColumnMeta}
                    columnData={keys}
                />
            </div>

                <div className='tool-box'>
                    <div className='form-container' id='create-license-key-box'>
                        <h2>Add License Key</h2>
                        <label className='form-label'>License Key</label>
                        <input
                            className='form-input'
                            ref={licenseKeyInputRef}
                            type='text'
                            placeholder='License Key'
                        />
                        <br />
                        <label className='form-label'>User Email</label>
                        <input
                            className='form-input'
                            ref={licenseKeyEmailInputRef}
                            type='text'
                            placeholder='User Email'
                        />
                        <br />
                        <label className='form-label'>Days To Expire</label>
                        <input
                            className='form-input'
                            ref={licenseKeyExpireRef}
                            type='number'
                            placeholder='Days To Expire'
                        />
                        <button className='form-submit' onClick={adminAddKey}>
                            Create
                        </button>
                    </div>
                    <br />
                </div></>)}


            {displayPage === 1 && <div className='tool-box'>
                <h2>View Feedback</h2>
                <DataTable
                    isLoading={isFeedbackLoading}
                    maxPageElements={20}
                    columnMeta={feedbackTableComulmnMeta}
                    columnData={feedback}
                />
            </div>}

            {displayPage === 2 && <div className='tool-box'>
                <h2>View Logins</h2>
                <DataTable
                    isLoading={isFeedbackLoading}
                    maxPageElements={20}
                    columnMeta={loginsMeta}
                    columnData={logins}
                />
            </div>}



            {displayPage === 3 && <div className='tool-box'>
                <h2>View Sign up Requests</h2>
                <DataTable
                    isLoading={isRequestsLoading}
                    maxPageElements={20}
                    columnMeta={requestTableComulmnMeta}
                    columnData={requests}
                />
            </div>}

            {displayPage === 4 && <div className='tool-box'>
                <h2>Support Tickets</h2>
                {supportTickets

                    .map((ticket) => (
                        <a href={`/admin/ticket/${ticket.id}`} className='ticket-box'>
                            <p>{ticket.title}</p>
                            <div className={`notification ${ticket.status === 'new' && ' alert'}`}></div>
                        </a>
                    ))}
            </div>}
        </div>
    );
};

export default AdminPage;
