import { useState, useRef } from "react";
import "./RequestModal.css";
import CloseIcon from "../../assets/close_icon.svg";
import axios from "axios";

const API_URL =
    process.env?.REACT_APP_API_URL ||
    "";

const RequestKeyModal = ({ shouldDisplay, closeModal }) => {
    const [doesAgreeToFeedback, setDoesAgreeToFeedback] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(null);
    const softwareUsageRef = useRef();

    const requestKey = () => {
        axios
            .post(`${API_URL}/request`, {
                userMessage: softwareUsageRef.current.value,
            })
            .then(() => {
                setDisplayMessage(
                    "Request made. You will receive an email soon."
                );
            })
            .catch((resp) => {
                if (resp.response.data === "Request already exists") {
                    setDisplayMessage("Request has already been made.");
                    return;
                }
                setDisplayMessage("Error making request");
            })
            .finally(() => {
                setTimeout(() => {
                    setDisplayMessage(null);
                }, 10000);
            });
    };

    if (!shouldDisplay) return null;
    return (
        <>
            <div className='modal-background'></div>
            <div className='modal'>
                <div>
                    <img
                        onClick={closeModal}
                        className='close-button'
                        src={CloseIcon}
                    />
                </div>
                <div>
                    <h1 className='modal-title'>Request a Key</h1>
                </div>
                <div className='request-question'>
                    <input
                        type='checkbox'
                        onClick={() =>
                            setDoesAgreeToFeedback(!doesAgreeToFeedback)
                        }
                        checked={doesAgreeToFeedback}
                    />
                    <label>Do you agree to submit feedback?</label>
                </div>

                <div className='request-question'>
                    <label>How do you plan to use the software?</label>
                    <br />
                    <textarea
                        ref={softwareUsageRef}
                        className='modal-textarea-input'
                    ></textarea>
                </div>

                <p className='feedback-info'>
                    By Requesting a license key you agree to submit feedback at
                    least one time. A license key will last 10 days before
                    expiring. By submitting feedback you can prolong the usage
                    of your license key.
                </p>
                <p className='feedback-info'>
                    Feedback can be submitted by emailing [EMAIL] or by
                    submitting a form on the homepage.
                </p>
                <button
                    className='modal-button'
                    disabled={!doesAgreeToFeedback}
                    onClick={requestKey}
                >
                    Request
                </button>
                <p className='display-message-text'>{displayMessage}</p>
            </div>
        </>
    );
};

export default RequestKeyModal;
