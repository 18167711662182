import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import RegconLogo from '../../assets/regcon.png';
import './index.css';

const API_URL = process.env?.REACT_APP_API_URL || '';

const TicketsPage = () => {
    // const ticketData = window.__TICKET_DATA__ || {
    //     title: 'Example Title',
    //     id: 'abc',
    // };

    const [shouldDisplayAdmin, setShouldDisplayAdmin] = useState(false);
    const [messages, setMessages] = useState([]);
    const [ticketData, setTicketData] = useState(null)
    const messageRef = useRef();
    const { ticket } = useParams();

    const checkIsAdmin = () => {
        axios.get(`${API_URL}/admin/display`).then((resp) => {
            if (resp.data.isAdmin) {
                setShouldDisplayAdmin(true);
            }
        });
    };

    useEffect(() => {
        checkIsAdmin();
        getMessages();
    }, []);

    const handleMessageInput = (e) => {
        console.log(e.target.style.lineHeight);
    };

    const sendMessage = () => {
        axios
            .post(`${API_URL}/api/tickets/${ticket}`, {
                message: messageRef.current.value,
            })
            .then((resp) => {
                messageRef.current.value = '';
                setMessages(resp.data.messages);
            });
    };

    const getMessages = () => {
        axios.get(`${API_URL}/api/tickets/${ticket}`).then((resp) => {
            setMessages(resp.data.messages);
            setTicketData(resp.data.ticket)
        });
    };

    const closeTicket = () => {
        axios.delete(`${API_URL}/api/tickets/${ticket}`)
            .then(() => {
                window.location.href = '/help'
            })
    }

    const messageDateString = (dateString) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        let date = new Date(dateString);
        if (date.toDateString() === today.toDateString()) {
            return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        } else if (date.toDateString() === yesterday.toDateString()) {
            return date.toLocaleString();
        } else {
            return date.toLocaleDateString();
        }
    };

    const getLines = (e) => {
        if (!e.target) return;
        const textarea = e.target
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 250)}px`;
    };

    return (
        <div className='body-container-m'>
            <div className='body-header'>
                <div className='body-header-side'>
                    <a className='header-link main-link' href='/'>
                        <img src={RegconLogo} alt='RegCon' />
                    </a>
                </div>
                <div className='body-header-side stays-on-mobile'>
                    <a className='header-link login-link' href='/home'>
                        Home
                    </a>
                    {shouldDisplayAdmin && (
                        <a className='header-link login-link' href='/admin'>
                            Admin
                        </a>
                    )}
                    <a className='header-link login-link' href='/logout'>
                        Logout
                    </a>
                </div>
            </div>

            <div className='main-ticket-display-container'>
                <h1>
                    <a href={`/help`}>Help</a> / Ticket
                </h1>
                <button className='close-ticket' onClick={closeTicket}>Close Ticket</button>
                <div className='ticket-conversation'>
                    <h2>{ticketData?.title}</h2>

                    <div className='conversation-box'>
                        {messages.map((message) => (
                            <div className='message'>
                                <p className={`convertsation-message ${message['is_support'] ? 'support' : 'user'}`}>{message.message}</p>
                                <p className={`message-timestamp ${message['is_support'] ? 'support' : 'user'}`}>Sent {messageDateString(message.time_sent)}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='send-message-container'>
                    <textarea
                        autosize
                        onInput={getLines}
                        ref={messageRef}
                        className='support-content-input'
                        placeholder='Your Message'
                    ></textarea>
                    <button className='support-content-button' onClick={sendMessage}>
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TicketsPage;
