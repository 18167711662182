import axios from "axios";
import { useEffect, useRef, useState } from "react";
import AccountRequestedModal from "../AccountRequestedModal";
import RegconLogo from "../../assets/regcon.png";
import SyringeImage from "../../assets/syringes.png";

import "./SignUp.css";

const API_URL = process.env?.REACT_APP_API_URL || "";

function SignUpPage() {
    const [page, setPage] = useState(0);
    const [acceptsFeedbackCondition, setAcceptsFeedbackCondition] =
        useState(false);
    const [acceptsTerms, setAcceptsTerms] = useState(false);
    const [displayCompletedModal, setDisplayCompletedModal] = useState(false);
    

    const nameRef = useRef();
    const companyRef = useRef();
    const phoneRef = useRef();
    const internationalCodeRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordVerificationRef = useRef();

    useEffect(() => {
        const scrollContainer = document.getElementById('split-container');

        // Disable mouse wheel scrolling
        scrollContainer.addEventListener('wheel', (event) => {
          event.preventDefault();
        }, { passive: false });
        
        // Disable touch scrolling
        scrollContainer.addEventListener('touchmove', (event) => {
          event.preventDefault();
        }, { passive: false });
    
    }, [])
   
    const moveToSignup = (e) => {
        e.preventDefault();
        document.getElementById("split-container").scrollLeft = 1000;
        setPage(1);
    };
    const moveToLogin = (e) => {
        e.preventDefault();
        document.getElementById("split-container").scrollLeft = 0;
        setPage(0);
    };

    const handleAccountCreation = (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const name = nameRef.current.value;
        const company = companyRef.current.value;
        const phone = phoneRef.current.value;
        const intlCode = internationalCodeRef.current.value;

        const emailRegex = /^[a-zA-Z0-9._\-\+]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,6}$/;
        const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!#%*?&-])[A-Za-z0-9@$!#%*?&-]{6,}$/;


        if (!acceptsFeedbackCondition || !acceptsTerms) {
            alert("Must accept to conditions");
            return;
        }

        if (!emailRegex.test(email)) {
            alert("Invalid email");
            return;
        }
        if (!regexPattern.test(password)) {
            alert("Password must include\n1 lowercase character\n1 uppercase character\n1 number\n6 characters");
            return;
        }

        if (!company.length) {
            alert("Company name must not be empty");
        }

        if (!name.length) {
            alert("Name must not be empty");
            return;
        }

        if (password !== passwordVerificationRef.current.value) {
            alert("Passwords must match");
            return;
        }
        axios
            .post(`${API_URL}/signup`, {
                name,
                company,
                phone,
                email,
                password,
                intlCode
            })
            .then((resp) => {
                // If successful
                setDisplayCompletedModal(true);
                // For now we are not going to login because the signup users will be contacted manually before getting their account
                // window.location.assign("/login");
            })
            .catch((resp) => {
                alert("Error. Account not created");
            });
    };

    const formatPhoneNumber = (event) => {
        const input = event.target;
        let formattedNumber = input.value.replace(/\D/g, "");

        // Formatting based on the length of the input
        if (formattedNumber.length <= 3) {
            // Up to 3 digits (area code or part of it)
            // formattedNumber = formattedNumber;
        } else if (formattedNumber.length <= 6) {
            // More than 3 digits, format as '(123) 456'
            formattedNumber = `(${formattedNumber.slice(
                0,
                3
            )}) ${formattedNumber.slice(3)}`;
        } else {
            // More than 6 digits, format as '(123) 456-7890'
            formattedNumber = `(${formattedNumber.slice(
                0,
                3
            )}) ${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6, 10)}`;
        }

        input.value = formattedNumber;
    };

    return (
        <div className='login-main-container'>
            <AccountRequestedModal
                shouldDisplay={displayCompletedModal}
                closeModal={() => {
                    window.location.assign("/");
                }}
            />
            <div className='login-display'>
                <div className='login-display-header'>
                    <a className='main-link' href={"/"}>
                        <img src={RegconLogo} alt='' />
                    </a>
                </div>
                <form onSubmit={handleAccountCreation} className='login-box'>
                    <div className='login-box-title'>
                        <h1>Welcome to Regcon Software.</h1>
                        <p>Sign up and get access to powerful software.</p>
                    </div>
                    <div className='page-display'>
                        <div className='page-display-indicator'>
                            <div
                                className={`page-display-indicator-icon ${
                                    !page ? "active" : "icon-done"
                                }`}
                            ></div>
                        </div>
                        <div
                            className={`page-display-indicator-line ${
                                page ? "completed-line" : ""
                            }`}
                        >
                            <div
                                className={`page-display-indicator-line-completeness ${
                                    page ? "completed" : ""
                                }`}
                            ></div>
                        </div>
                        <div className='page-display-indicator'>
                            <div
                                className={`page-display-indicator-icon ${
                                    page ? "active" : ""
                                }`}
                            ></div>
                        </div>
                    </div>
                    <div
                        className='split-login-box-container'
                        id='split-container'
                        onScroll={e => e.preventDefault()}
                        onTouchMove={e => e.preventDefault()}
                    >
                        <div className='split-login-box' id='basic-info-box'>
                            <label>Email *</label>
                            <input
                                placeholder='Email'
                                className='primary-input login-input'
                                ref={emailRef}
                            />
                            <label>Name *</label>
                            <input
                                placeholder='Name'
                                className='primary-input login-input'
                                ref={nameRef}
                            />
                            <label>Company *</label>
                            <input
                                placeholder='Company'
                                className='primary-input login-input'
                                ref={companyRef}
                            />
                            <label>Phone Number</label>
                            <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                                <input ref={internationalCodeRef} defaultValue={'+1'} placeholder='International Code' className='primary-input login-input intlcode' />
                                <input
                                    onChange={(e) => formatPhoneNumber(e)}
                                    placeholder='Phone Number'
                                    className='primary-input login-input'
                                    ref={phoneRef}
                                />
                            </div>

                            <button
                                onClick={moveToSignup}
                                className='primary-button login-input'
                            >
                                Next
                            </button>
                        </div>
                        <div className='split-login-box' id='account-info-box'>
                            <button
                                onClick={moveToLogin}
                                className='primary-button login-input back-button'
                            >
                                Back
                            </button>
                            <br />
                            <label>Password *</label>
                            <input
                                type='password'
                                placeholder='Password'
                                className='primary-input login-input'
                                ref={passwordRef}
                            />
                            <label>Re-enter Password *</label>
                            <input
                                type='password'
                                placeholder='Re-enter Password'
                                className='primary-input login-input'
                                ref={passwordVerificationRef}
                            />
                            <div>
                                <label>
                                    Do you agree to submit feedback during the
                                    beta period? *
                                </label>
                                <input
                                    type='checkbox'
                                    onChange={(e) =>
                                        setAcceptsFeedbackCondition(
                                            e.target.checked
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label>
                                    Do you agree to the terms of use? *
                                </label>
                                <input
                                    type='checkbox'
                                    onChange={(e) =>
                                        setAcceptsTerms(e.target.checked)
                                    }
                                />
                            </div>
                            <button
                                onClick={handleAccountCreation}
                                className='primary-button login-input'
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                    <p>
                        Already have an account? <a href={"/login"}>Login!</a>
                    </p>
                </form>
            </div>
            <div className='login-decorator-panel'>
                <img src={SyringeImage}  alt='' />
            </div>
        </div>
    );
}

export default SignUpPage;
