import './AccountRequestedModal.css'
import CloseIcon from "../../assets/close_icon.svg";

const AccountRequestedModal = ({shouldDisplay, closeModal}) => {


    if (!shouldDisplay) return null;
    return ( <>
        <div className='modal-background'></div>
        <div className='modal'>
            <div>
                <img
                    onClick={closeModal}
                    className='close-button'
                    src={CloseIcon}
                    alt=''
                />
            </div>
            <div>
                <h1 className='modal-title'>Account Requested</h1>
            </div>

            <p className='modal-main-text'>Your account has been requested! Please check your email in 24-72 hours for your account to be created.</p>
           

           <p className='feedback-info'>

            </p>
            <button
                className='modal-button'
                onClick={closeModal}
            >
                Okay
            </button>
        </div>
    </>)
}
export default AccountRequestedModal