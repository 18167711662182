import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import RegconLogo from '../../assets/regcon.png';

import contactIcon from '../../assets/contact.svg';
import curiousIcon from '../../assets/curious.svg';
import securityIcon from '../../assets/security.svg';
import authKeyIcon from '../../assets/auth-key.svg';
import interactIcon from '../../assets/interact.svg';

const API_URL = process.env?.REACT_APP_API_URL || '';

const HelpCategoryPage = () => {
    const ARTICLES = window.__ARTICLES__ || [
        {
            "title": "How do I get a key?",
            "url": "how-do-i-get-a-key",
            "content": "Right now keys are free in beta! This means that just by signing up for an account and agreeing to supply feedback during the beta period, you can gain acess to a free copy as long as the beta lasts."
        },
        {
            "title": "How long is a key valid?",
            "url": "how-long-is-a-key-valid",
            "content": "Currently, a key is valid for as long as you supply feedback or as long as the beta is active."
        }
    ];
    const CATEGORY = window.__CATEGORY__ || "F.A.Q's";
    const { category } = useParams();
    const [shouldDisplayAdmin, setShouldDisplayAdmin] = useState(false);

    const checkIsAdmin = () => {
        axios.get(`${API_URL}/admin/display`).then((resp) => {
            if (resp.data.isAdmin) {
                setShouldDisplayAdmin(true);
            }
        });
    };

    useEffect(() => {
        checkIsAdmin();
    }, []);

    return (
        <div className='help-desk-container'>
            <div className='body-header'>
                <div className='body-header-side'>
                    <a className='header-link main-link' href='/'>
                        <img src={RegconLogo} alt='RegCon' />
                    </a>
                </div>
                <div className='body-header-side stays-on-mobile'>
                    <a className='header-link login-link' href='/home'>
                        Home
                    </a>
                    {shouldDisplayAdmin && (
                        <a className='header-link login-link' href='/admin'>
                            Admin
                        </a>
                    )}
                    <a className='header-link login-link' href='/logout'>
                        Logout
                    </a>
                </div>
            </div>

            <div className='help-article-container'>
                <h1> <a href={`/help`}>Help</a> /{CATEGORY}</h1>
                {ARTICLES.map((article) => (
                    <a href={`/help/articles/${category}/${article.url}`} className='search-result'>
                        <p className='search-result-title'>{article.title}</p>
                        <p className='search-result-preview'>{article.content}</p>
                    </a>
                ))}
            </div>

            <div className='question-section'>
                <a href='viewer' className='question-genre'>
                    <h4>Using the viewer</h4>
                    <p>What does what?</p>
                    <img src={interactIcon} alt='' />
                </a>
                <a href='authentication' className='question-genre'>
                    <h4>Authentication</h4>
                    <p>Problem logging in?</p>
                    <img src={authKeyIcon} alt='' />
                </a>

                <a href='security' className='question-genre'>
                    <h4>Data and Security</h4>
                    <p>How is your data stored?</p>
                    <img src={securityIcon} alt='' />
                </a>
                <a href='faq' className='question-genre'>
                    <h4>F.A.Q's</h4>
                    <p>Still curious?</p>
                    <img src={curiousIcon} alt='' />
                </a>
                <div className='question-genre contact-us'>
                    <h4>Contact Us</h4>
                    <p>Something else?</p>
                    <img src={contactIcon} alt='' />
                </div>
            </div>

            <div className='body-footer'>
                <img className='body-footer-logo' src={RegconLogo} alt='RegCon Logo' />
                <div className='footer-button-row'>
                    <a href='/#features'>Features</a>
                    <a href='/#how-it-works'>How it Works</a>
                    <a href='/#pricing'>Pricing</a>
                    <a href='/#contact-us'>Contact Us</a>
                    <a href='/privacy'>Privacy Policy</a>
                    <a href='/terms'>Terms & Conditions</a>
                </div>
                <p className='footer-text'>Copyright © RegCon Solutions, LLC. All rights reserved.</p>
            </div>
        </div>
    );
};

export default HelpCategoryPage;
