import { useState, useRef } from "react";
import "./DataTable.css";

const DataTable = ({ columnMeta, columnData, maxPageElements, isLoading }) => {
    const filterOptionRef = useRef();
    const filterOptionInputRef = useRef();

    const [currentPage, setCurrentPage] = useState(0);
    const [tableFilters, setTableFilters] = useState([]);
    const [buildingFilter, setBuildingFilter] = useState({});

    const filterOptions = {
        text: ["Contains", "Equals", "Does not contain", "Does not equal"],
        date: ["Before", "On", "After"],
        number: ["Greater than", "Equal to", "Less than"],
    };

    const filterOptionsFunctions = {
        Contains: (A, B) => A.includes(B),
        Equals: (A, B) => A === B,
        "Does not contain": (A, B) => !A.includes(B),
        "Does not equal": (A, B) => A !== B,
        Before: (A, B) => A < B,
        On: (A, B) => A === B,
        After: (A, B) => A > B,
        "Greater than": (A, B) => A > B,
        "Equal to": (A, B) => A === B,
        "Less than": (A, B) => A < B,
    };

    const columnNames = columnMeta.map((column) => column.name);


    const maxPageNumber = Math.floor(
        columnData.filter((column) => {
            return !tableFilters.length || tableFilters[0].filter(column);
        }).length / maxPageElements
    );

    const addFilter = () => {
        let keyToUse = buildingFilter.key;
        let functionToUse = filterOptionRef.current.value;
        let filterInput = filterOptionInputRef.current.value;

        setTableFilters([
            ...tableFilters,
            {
                filter: (column) => {
                    return filterOptionsFunctions[functionToUse](
                        column[keyToUse],
                        filterInput
                    );
                },
                name: `"${
                    buildingFilter.name
                }" ${functionToUse.toLowerCase()} "${filterInput}"`,
            },
        ]);
    };

    const removeFilter = (idx) => {
        let tempTableFilters = [...tableFilters];
        tempTableFilters.splice(idx, 1);
        setTableFilters(tempTableFilters);
    };

    const applyFilters = () => {
        let filteredData = [...columnData]; // Create a copy of the data array

        for (const filterObj of tableFilters) {
            filteredData = filteredData.filter((column) =>
                filterObj.filter(column)
            );
        }
        return filteredData;
    };

    return (
        <div>
            <p className='filter-row-title'>Add Filter</p>
            <div className='filter-row'>
                <select
                    className='inner-table-select'
                    onChange={(e) =>
                        setBuildingFilter(JSON.parse(e.target.value))
                    }
                >
                    <option value={"{}"}>Select</option>
                    {columnMeta
                        .filter((column) => column.filterable)
                        .map((column, idx) => (
                            <option value={JSON.stringify(column)}>
                                {column.name}
                            </option>
                        ))}
                </select>
                {Object.keys(buildingFilter).length ? (
                    <>
                        <select
                            ref={filterOptionRef}
                            className='inner-table-select'
                        >
                            {(
                                filterOptions[
                                    buildingFilter?.filterInputType
                                ] || []
                            ).map((filterOption) => (
                                <option>{filterOption}</option>
                            ))}
                        </select>
                        <input
                            className='inner-table-input'
                            placeholder='Enter Value'
                            ref={filterOptionInputRef}
                            type={buildingFilter?.filterInputType || "text"}
                        ></input>
                    </>
                ) : null}

                <button
                    className='inner-table-button'
                    disabled={!Object.keys(buildingFilter).length}
                    onClick={addFilter}
                >
                    Add
                </button>
            </div>

            <div className='filter-entry-container'>
                {tableFilters.map((filter, idx) => (
                    <div className='filter-entry'>
                        <p>
                            {idx + 1}. {filter.name}
                        </p>
                        <button
                            className='inner-table-button'
                            onClick={() => removeFilter(idx)}
                        >
                            Delete
                        </button>
                    </div>
                ))}
            </div>

            <table className='table-dark'>
                <thead>
                    <tr>
                        {columnNames.map((name) => (
                            <td>{name}</td>
                        ))}
                    </tr>
                </thead>

            { isLoading ? null : <tbody>
                {applyFilters()
                    .slice(
                        currentPage * maxPageElements,
                        (currentPage + 1) * maxPageElements
                    )
                    .map((column, idx) => (
                        <tr>
                            {columnMeta.map((meta) => (
                                <td>
                                    {meta.render(
                                        column[meta.key] || meta.default,
                                        column,
                                        idx
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
            </tbody>}
            </table>
            {isLoading ? <div className="loader"></div> : null}
            <div className='table-render-text-box'>
                <p className='table-render-text'>
                    Page {currentPage + 1} / {maxPageNumber + 1}
                </p>
                <p className='table-render-text'>
                    Rows {currentPage * maxPageElements + 1} -{" "}
                    {Math.min(
                        applyFilters().length,
                        (currentPage + 1) * maxPageElements + 1
                    )}{" "}
                    of {applyFilters().length} shown
                </p>
            </div>
            <button
                className='table-button'
                disabled={currentPage === 0}
                onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
            >
                Previous Page
            </button>
            <button
                className='table-button'
                disabled={currentPage === maxPageNumber}
                onClick={() =>
                    setCurrentPage(Math.min(currentPage + 1, maxPageNumber))
                }
            >
                Next Page
            </button>
        </div>
    );
};

export default DataTable;
