import { useRef, useState } from 'react';
import axios from 'axios';

import './Landing.css';

import RegconLogo from '../../assets/regcon.png';
import ViewerIcon from '../../assets/viewer-icon.svg';
import SubmissionIcon from '../../assets/submission.svg';
import KeyIcon from '../../assets/key.svg';
import ProgressIcon from '../../assets/progress.svg';
import AddIcon from '../../assets/add.svg';
import PackageIcon from '../../assets/package.svg';
import ViewIcon from '../../assets/view.svg';
import SettingIcon from '../../assets/setting.svg';
import SmallViewIcon from '../../assets/small-view.svg';
import CheckIcon from '../../assets/check.svg';
import MenuIcon from '../../assets/menu.svg';

const API_URL = process.env?.REACT_APP_API_URL || '';

function HomePage() {
    const [menuOpen, setMenuOpen] = useState(false);

    const userNameRef = useRef();
    const userEmailRef = useRef();
    const userPhoneRef = useRef();
    const userSubjectRef = useRef();
    const userMessageRef = useRef();

    function getAuthenticationCookie() {
        const cookieName = 'Authentication'; // Replace 'authToken' with the name of your authentication cookie
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    const isLoggedIn = getAuthenticationCookie();

    const submitDetails = () => {
        if (
            !userNameRef.current.value ||
            !userEmailRef.current.value ||
            !userPhoneRef.current.value ||
            !userSubjectRef.current.value ||
            !userMessageRef.current.value
        ) {
            alert('Please fill in all the fields');
        } else {
            axios
                .post(`${API_URL}/contactus`, {
                    name: userNameRef.current.value,
                    email: userEmailRef.current.value,
                    phone: userPhoneRef.current.value,
                    subject: userSubjectRef.current.value,
                    message: userMessageRef.current.value,
                })
                .then(() => {
                    alert('Your message has been sent successfully');
                })
                .catch(() => {
                    alert('Your message was not successful');
                });
        }
    };

    const handleMenuOpen = () => {
        setMenuOpen((isOpen) => !isOpen);
    };

    return (
        <div className={`main-landing-container ${menuOpen ? 'disable-scroll' : ''}`}>
            <div className='body-header'>
                <div className='body-header-side'>
                    <div style={{ height: '2.5rem' }}>
                        <a className='header-link main-link-regcon' href='/'>
                            <img src={RegconLogo} alt='RegCon Logo' />
                        </a>
                    </div>
                </div>

                <div className='menu-button' onClick={handleMenuOpen}>
                    <img src={MenuIcon} alt='' />
                </div>
                <div className={`body-header-side ${menuOpen ? 'visible' : ''}`}>
                    <div className='expanded-row-on-mobile'>
                        <a className='header-link main-link-regcon' href='/'>
                            <img src={RegconLogo} alt='RegCon Logo' />
                        </a>
                        <div className='menu-button' onClick={handleMenuOpen}>
                            <img src={MenuIcon} alt='' />
                        </div>
                    </div>
                    <div className='header-link-container'>
                        <a className='header-link id-link' href='#features'>
                            Features
                        </a>
                    </div>

                    <div className='header-link-container'>
                        <a className='header-link id-link' href='#how-it-works'>
                            How it works
                        </a>
                    </div>
                    <div className='header-link-container'>
                        <a className='header-link id-link' href='#pricing'>
                            Pricing
                        </a>
                    </div>
                    <div className='header-link-container'>
                        <a className='header-link id-link' href='#contact-us'>
                            Contact Us
                        </a>
                    </div>
                    {isLoggedIn ? (
                        <div className='header-link-container'>
                            <a className='header-link button-link-light' href='/home'>
                                Home
                            </a>
                        </div>
                    ) : (
                        <>
                            <div className='header-link-container'>
                                <a className='header-link button-link-light' href='/login'>
                                    Log In
                                </a>
                            </div>
                            <div className='header-link-container'>
                                <a className='header-link button-link' href='/signup'>
                                    Sign Up
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className='body-container-landing partA'>
                <div className='body-section first-section'>
                    <div className='first-section-left'>
                        <h1>View your submissions like never before.</h1>
                        <h2>Powerful software to acess, manage, and view your eCTD submissions</h2>
                        <a href='/signup' className='get-started-button'>
                            Get Started
                        </a>
                    </div>
                    <div className='first-section-right'>
                        <img className='viewer-display-icon' src={ViewerIcon} alt='' />
                    </div>
                </div>
            </div>
            <div className='body-container-landing partB'>
                <div className='feature-showcase' id='features'>
                    <h2>Features</h2>
                    <div className='feature-container'>
                        <div className='feature'>
                            <div className='feature-icon-box'>
                                <img className='feature-image' src={SubmissionIcon} alt='' />
                            </div>
                            <p className='feature-title'>Master Submission Management</p>
                            <p className='feature-description'>
                                Effortlessly orchestrate your submission process with our intuitive platform. From
                                metadata to comments, stay in command of every detail and milestone in your submission
                                lifecycle.
                            </p>
                        </div>
                        <div className='feature'>
                            <div className='feature-icon-box'>
                                <img className='feature-image' src={PackageIcon} alt='' />
                            </div>
                            <p className='feature-title'>Dynamic Sequence Visualization</p>
                            <p className='feature-description'>
                                Elevate your workflow with our flexible rendering options. View and contrast sequences
                                with unparalleled ease, aligning different submissions side by side or in structured
                                tabs.
                            </p>
                        </div>
                        <div className='feature'>
                            <div className='feature-icon-box'>
                                <img className='feature-image' src={ViewIcon} alt='' />
                            </div>
                            <p className='feature-title'>Enhansed Submission Display</p>
                            <p className='feature-description'>
                                Navigate your documents and sequences with finesse. Our app's robust tools are crafted
                                to help you swiftly locate and display the essentials of your eCTD submissions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='complete-feature-list'>
                    <p>Feature List</p>
                    <ul>
                        <li>Render cumulative, current, single sequence, or custom selected sequences</li>
                        <li>Render multiple sequnces in tabs or side by side</li>
                        <li>Render multiple submissions in tabs or side by side</li>
                        <li>View PDFs in app and search for text</li>
                        <li>View XML documents in app</li>
                        <li>Quickly open word files and other formats in programs of your choice</li>
                        <li>Use PDF hyperlinks between documents and to places inside the document</li>
                        <li>Discover incorrect md5 hashes</li>
                        <li>See metadata for leafs and directories of your submissions</li>
                        <li>See which leafs have been replaced easily</li>
                        <li>Keep track of your submissions and store comments</li>
                        <li>Save view data in xml format</li>
                        <li>More being added every day</li>
                    </ul>
                </div>
            </div>

            <div className='body-container-landing partC' id='how-it-works'>
                <h2>How it Works</h2>

                <div className='row-box'>
                    <div className='how-step'>
                        <img src={KeyIcon} alt='' />
                        <h3>Submit Your App Key</h3>
                        <p>Kickstart your journey by activating the eCTD Viewer with your unique application key.</p>
                        <img src={ProgressIcon} alt='' />
                    </div>
                    <div className='how-step'>
                        <img src={AddIcon} alt='' />
                        <h3>Upload and Organize</h3>
                        <p>
                            Seamlessly add your submissions and manage your lifecycles with advanced organizational
                            tools.
                        </p>
                        <img src={ProgressIcon} alt='' />
                    </div>
                    <div className='how-step'>
                        <img src={SettingIcon} alt='' />
                        <h3>Multifaceted Management</h3>
                        <p>Manage your submissions with comments, and metadata for enhanced oversight.</p>
                        <img src={ProgressIcon} alt='' />
                    </div>
                    <div className='how-step'>
                        <img src={SmallViewIcon} alt='' />
                        <h3>Display Your Submission</h3>
                        <p>
                            Display your submissions in a tailored view, from side-by-side comparisons to in-app
                            document explorations.
                        </p>
                        <img src={ProgressIcon} alt='' />
                    </div>
                </div>
            </div>
            <div className='body-container-landing partD' id='pricing'>
                <h2>Pricing Plans</h2>
                <div className='row-box-b'>
                    <div className='price-plan-box'>
                        <h3>Free During Beta</h3>
                        <div style={{ display: 'flex', justifyContent: 'left', alignContent: 'center', gap: 10 }}>
                            <img src={CheckIcon} alt='' />
                            <p>Unlimited Submissions</p>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'left', alignContent: 'center', gap: 10 }}>
                            <img src={CheckIcon} alt='' />
                            <p>Unlimited Submissions</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignContent: 'center', gap: 10 }}>
                            <img src={CheckIcon} alt='' />
                            <p>Access to all features</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='body-container-landing partE' id='contact-us'>
                <div className='contact-us-container'>
                    <h2>Contact Us</h2>
                    <p>Get more insight on your lifecycles with RegCon eCTD Navigator</p>
                    <div className='contact-form-box'>
                        <div className='contact-row-box'>
                            <div className='contact-input-box'>
                                <label>Your Name</label>
                                <br />
                                <input ref={userNameRef} name='name' type='text' placeholder='Your Name Here' />
                            </div>
                            <div className='contact-input-box'>
                                <label>Your Email</label>
                                <br />
                                <input ref={userEmailRef} name='email' type='text' placeholder='Your Email Here' />
                            </div>
                        </div>

                        <div className='contact-row-box'>
                            <div className='contact-input-box'>
                                <label>Your Phone</label>
                                <br />
                                <input
                                    ref={userPhoneRef}
                                    name='phone'
                                    type='text'
                                    placeholder='Your Phone Number Here'
                                />
                            </div>
                            <div className='contact-input-box'>
                                <label>Subject</label>
                                <br />
                                <input ref={userSubjectRef} name='subject' type='text' placeholder='Enter Subject' />
                            </div>
                        </div>

                        <div className='contact-row-box'>
                            <div className='contact-input-box'>
                                <label>Message</label>
                                <br />
                                <textarea ref={userMessageRef} placeholder='Enter Message Here' rows={5} />
                            </div>
                        </div>
                    </div>

                    <button className='contact-submit-button' onClick={submitDetails}>
                        Submit
                    </button>
                </div>
            </div>

            <div className='body-footer'>
                <img className='body-footer-logo' src={RegconLogo} alt='RegCon Logo' />
                <div className='footer-button-row'>
                    <a href='/#features'>Features</a>
                    <a href='/#how-it-works'>How it Works</a>
                    <a href='/#pricing'>Pricing</a>
                    <a href='/#contact-us'>Contact Us</a>
                    <a href='/privacy'>Privacy Policy</a>
                    <a href='/terms'>Terms & Conditions</a>
                </div>
                <p className='footer-text'>Copyright © RegCon Solutions, LLC. All rights reserved.</p>
            </div>
        </div>
    );
}

export default HomePage;
