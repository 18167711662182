import axios from "axios";
import { useRef } from "react";
import "./Login.css";
import RegconLogo from "../../assets/regcon.png";
import BuildingImage from "../../assets/building.png";
import DoctorImage from "../../assets/doctor.png";

const API_URL = process.env?.REACT_APP_API_URL || "";

function App() {
    const emailRef = useRef();
    const passwordRef = useRef();

    const handleLogin = (e) => {
        e.preventDefault()
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        console.log(API_URL);
        axios
            .post(`${API_URL}/login`, { email, password })
            .then((resp) => {
                // localStorage.setItem('authToken', resp.data.authToken);
                window.location.assign("/home");
            })
            .catch((resp) => {
                if (resp.response.status === 401) {
                    alert("Login Credentials Failed");
                } else {
                    alert("Error Logging In");
                }
            });
    };

    return (
        <div className='login-main-container'>
            <div className='login-display'>
                <div className='login-display-header'>
                    <a className='main-link' href={"/"}>
                        <img src={RegconLogo} />
                    </a>
                </div>
                <form onSubmit={handleLogin} className='login-box'>
                    <div className='login-box-title'>
                        <h1>Welcome to Regcon Software.</h1>
                        <p>
                            Log in and get access to software and license keys.
                        </p>
                    </div>
                        <label>Email</label>
                        <input
                            type='email'
                            placeholder='Email'
                            name='email'
                            className='primary-input login-input'
                            ref={emailRef}
                        />
                        <label>Password</label>
                        <input
                            type='password'
                            placeholder='Password'
                            name="password"
                            autoComplete="current-password"
                            className='primary-input login-input'
                            ref={passwordRef}
                        />

                        <input
                            //onClick={handleLogin}
                            className='primary-button login-input'
                            autoComplete="password"
                            type="submit"
                            value='Login'
                            
                        />
                                                <p>
                        Dont have an account? <a href={"/signup"}>Sign Up!</a>
                    </p>
                </form>
            </div>
            <div className='login-decorator-panel'>
                <img src={DoctorImage} alt='' />
            </div>
        </div>
    );
}

export default App;
